<template>
  <div id="product-mapping">
    <v-data-table
      class="elevation-1 mb-5"
      :headers="header"
      :items="prototypes"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :server-items-length="itemsLength"
      :loading="loading"
      :height="530"
      hide-default-footer
      @update:page="optionsUpdated()"
      @update:sort-by="optionsUpdated()"
      @update:sort-desc="optionsUpdated()">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              Product Mapping
            </span>
          </v-toolbar-title>
          <v-spacer />
          <search-box
            v-model="searchText"
            @on-search="search()" />
        </v-toolbar>
      </template>
      <template v-slot:[`item.order.orderId`]="{ item }">
        {{ item.order.orderId | paddingNumber() }}
      </template>
      <template v-slot:[`item.targetDate`]="{ item }">
        {{ dateCompute(item.order.targetDate) }}
      </template>
      <template v-slot:[`item.map`]="{ item }">
        <v-btn
          :to="{
            name: 'ProductMappingDetailOld',
            params: { id: item.id }
          }"
          icon>
          <v-icon
            small
            class="mr-2">
            mdi-book-arrow-left-outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
      v-if="paginationEnable"
      v-model="options.page"
      :total-visible="5"
      :length="pageCount"
      :disabled="loading" />
  </div>
</template>

<script>
import PrototypeProviderOld from '@/resources/PrototypeProviderOld'
import SearchBox from '@/components/SearchBox.vue'

const PrototypeService = new PrototypeProviderOld()

export default {
  components: { SearchBox },
  data () {
    return {
      searchText: '',
      dialog: false,
      loading: false,
      pageCount: 0,
      itemsLength: 0,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['order.orderId'],
        sortDesc: [true]
      },
      header: [
        {
          text: 'Product Id',
          value: 'order.productId',
          width: 100
        },
        {
          text: 'Order ID',
          value: 'order.orderId',
          width: 100
        },
        {
          text: 'Sketch ID',
          value: 'sketch.sketchId',
          width: 100
        },
        {
          text: 'Designer',
          value: 'sketch.designer.name',
          width: 100
        },
        {
          text: 'Merchandiser',
          value: 'sketch.merchandiser.name',
          width: 100
        },
        {
          text: 'Type',
          value: 'sketch.category.name',
          width: 100
        },
        {
          text: 'Delivered Days',
          value: 'targetDate',
          width: 100
        },
        {
          text: 'Select',
          value: 'map',
          sortable: false,
          width: 60,
          align: 'end'
        }
      ],
      prototypes: [],
      getting: false
    }
  },
  computed: {
    paginationEnable () {
      return this.pageCount > 1
    }
  },
  created () {
    this.getPrototype()
  },
  methods: {
    optionsUpdated () {
      this.getPrototype()
    },
    dateCompute (date) {
      return date ? this.$dayjs(date).format('DD/MM/YYYY') : '-'
    },
    search () {
      this.prototypes = []
      this.options.page = 1
      this.pageCount = 0
      this.itemsLength = 0
      this.options.sortBy[0] = 'createdAt'
      this.options.sortDesc[0] = true
      this.getPrototype()
    },
    async getPrototype () {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true
          const { data } = await PrototypeService.getPrototypes({
            state: ['order', 'manufacturing', 'mapped'],
            page: this.options.page,
            perPage: this.options.itemsPerPage,
            search: this.searchText,
            sortBy: this.options.sortBy[0],
            sortOrder: this.options.sortDesc[0] === true ? 'desc' : 'asc'
          })
          this.prototypes = data.results
          this.pageCount = data.pages
          this.itemsLength = data.total
        } catch (error) {
          console.error('get: ', error)
          this.setErrorPage(error.code)
        } finally {
          this.loading = false
          this.getting = false
        }
      }
    },
    capitalize (value) {
      if (!value) {
        return ''
      }
      return value.toString().charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>
